import { ref, computed, watchEffect } from "vue";

let displayWidth = ref(0);
let displayHeight = ref(0);
let responsiveClassMap = config.style.responsive.classMap;

let largerAndSmallerComputedProperties = {};
for (const [key, value] of Object.entries(responsiveClassMap)) {
  let smallerKey = `isSmallerThan${utilities.ucFirst(key)}`;
  let largerKey = `isLargerThan${utilities.ucFirst(key)}`;

  largerAndSmallerComputedProperties[smallerKey] = computed(() => {
    return displayWidth.value <= value;
  });
  largerAndSmallerComputedProperties[largerKey] = computed(() => {
    return displayWidth.value > value;
  });
}

let isMobile = computed(() => {
  let targetBreakPoint = config.style.responsive.mainMobileBreakPoint;
  let computedPropertyName = `isSmallerThan${utilities.ucFirst(targetBreakPoint)}`;

  if (!largerAndSmallerComputedProperties.hasOwnProperty(computedPropertyName)) {
    warn(
      "dom helper - can not compute is mobile because the computed property is not available. Check your style.responsive configuration"
    );
    return true;
  }
  return largerAndSmallerComputedProperties[computedPropertyName].value;
});
let updateDisplaySizeFromDom = () => {
  if (utilities.isSSR()) {
    return false;
  }
  displayWidth.value = window.innerWidth;
  displayHeight.value = window.innerHeight;
  return true;
};
let displaySizeDebounce = null;
if (!utilities.isSSR()) {
  // set initial display size and watch for changes
  updateDisplaySizeFromDom();
  displaySizeDebounce = utilities.debounce(updateDisplaySizeFromDom, 100, {
    returnType: "all",
  });
  window.addEventListener("resize", function () {
    displaySizeDebounce.clear();
    displaySizeDebounce.run();
  });
}

const scrollToElement = (hash, options = { offset: 120 }) => {
  if (utilities.isSSR()) {
    return true;
  }

  let element = document.querySelector(hash);

  if (!element) return true;

  const y = element.getBoundingClientRect().top + window.scrollY - options.offset;
  window.scrollTo({ top: y, behavior: "smooth" });
};

export default () => {
  return {
    displayWidth,
    displayHeight,
    ...largerAndSmallerComputedProperties,
    isMobile,
    scrollToElement,
  };
};
